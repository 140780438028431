const getters = {
  user: (state) => state.user,
  isAuth: (state) => state.user?.isAuth,
  isIndividual: (state) => state.user.is_individual,
  isModerator: (state) => state.user?.is_moderator,
  isSeller: (state) => state.user?.is_seller,
  favoritesCount: (state) => state.favoritesCount,
  cartCount: (state) => state.cartCount,
  myMusic: (state) => state.myMusic,
  myCollection: (state) => state.myCollection,
  collectionCount: (state) => state.collectionCount,
  arrangementsCount: (state) => state.arrangementsCount,
  canDownloadContract: (state) => state.user?.author_contract,
  mySlug: (state) => state.user?.slug,

}

export default getters
