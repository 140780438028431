const getters = {
  trackList: (state) => state.trackList,
  nextPageUrl: (state) => state.nextPageUrl,
  nextPage: (state) => {
    if (state.nextPageUrl) {
      const url = new URL(state.nextPageUrl);
      const params = new URLSearchParams(url.search);
      return params.get('page')
    }
    return null

  },
  track: (state) => state.track,
  tracksMoreLikeThis: (state) => state.trackList
}

export default getters
