
  import { string } from "vue-types"
  import downloadDocumentIcon from "../svg/downloadDocumentIcon"

  export default {
    name: "DownloadDocumentComponent",
    components: { downloadDocumentIcon },
    props: {
      url: string().def("")
    },
    methods: {}
  };
