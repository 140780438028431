
  import { string, bool } from "vue-types";

  export default {
    name: "ThumbnailComponent",
    props: {
      thumbnail: string().def(""),
      isNew: bool().def(false),
      isDetail: bool().def(false)
    }
  };
