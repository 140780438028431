export const breadcrumbs = {
  index: 'главная',
  catalog: 'каталог музыки',
  account: 'личный кабинет',
  favorites: 'избранное',
  cart: 'корзина',
  my_music: 'моя музыка',
  collection: 'мои заказы',
  authors: 'авторы',
  contract: 'договор',
  invoice: 'счета',
  // settings: 'настройки'
}
