
  import {string, bool} from 'vue-types'

  export default {
    name: "ButtonComponent",
    props: {
      url: string().def(''),
      bgColor: string().def(undefined),
      isSecondary: bool().def(false),
      isIcon: bool().def(false),
      hasBorder: bool().def(true),
      bgAccentColor: string().def(undefined),
      isBanner: bool().def(false),
    },
    computed: {
      buttonStyle() {
        return {
          '--bg-color': this.bgColor,
          '--bg-accent-color': this.bgAccentColor,
        }
      },
      buttonClasse() {
        if (this.isBanner) return 'button__banner'
        if (this.isIcon) {
          return 'button__icon'
        } else {
          return this.isSecondary ? 'button__secondary': 'button__primary'
        }
      },
    },
    methods: {
      handleClick() {
        this.$emit('onClick')
      },
    }
  }
