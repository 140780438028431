// TODO: Поправить когда будет новый ЛК.
// TODO: добавить вывод уведомлений
function parseError(status, messages, redirect) {
  // error
  if (status === 404) messages = 'Объект не найден';
  if (status === 403 && messages.detail) {
    messages = messages.detail;
    const timer = setTimeout(() => {
      redirect('/ru/login/');
      clearTimeout(timer)
    }, 500)
  }
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject(new Error(messages))
    }
    if (messages instanceof Object) {
      return Promise.reject(messages)
    }
    return Promise.reject(new Error(messages))

  } else {
    return Promise.reject(new Error('Неизвестная ошибка'))
  }
}

function parseBody(response) {
  if ([200, 201, 204].includes(response.status)) {
    return response
  } else {
    return this.parseError(response.status, response.data.messages)
  }
}

export default function ({$axios, redirect}, inject) {
  const api = $axios.create({
    withCredentials: true,
    headers: {
      common: {
        "Access-Control-Allow-Origin": '*',
      },
      get: {
        "Accept-Language": 'ru',
      },
    }
  })

  api.defaults.xsrfHeaderName = 'x-csrftoken'
  api.defaults.xsrfCookieName = 'csrftoken'


  api.interceptors.response.use((response) => {
    return parseBody(response)
  }, error => {
    // eslint-disable-next-line no-console
    console.warn('Error status', error.response?.status, error.response?.data)
    if (error.response) {
      return parseError(error.response.status, error.response.data, redirect)
    } else {
      return Promise.reject(error)
    }
  })

  // Inject to context as $api
  inject('api', api)
}
