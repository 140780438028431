
  import {string, number, bool, oneOfType} from 'vue-types';

  export default {
    name: 'InputComponent',
    props: {
      additionalClass: string().def(''),
      value: oneOfType([string(), number()]),
      placeholder: string().def(''),
      disabled: bool().def(false)
    },
    emits: ['input'],

    computed: {
      inputValue: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('onChange', val);
        }
      },
    },
    methods: {
      onEnter(e) {
        this.$emit('onEnter', e.target.value);
      }
    },
  };
