const getters = {
  nextPageUrl: (state) => state.nextPageUrl,
  nextPage: (state) => {
    if (state.nextPageUrl) {
      const url = new URL(state.nextPageUrl);
      const params = new URLSearchParams(url.search);
      return params.get("page");
    }
    return null;
  },
  pageCount: (state) => state.pageCount,
};

export default getters;
