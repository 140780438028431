import { render, staticRenderFns } from "./SearchComponent.vue?vue&type=template&id=3ca34b95&scoped=true&"
import script from "./SearchComponent.vue?vue&type=script&lang=js&"
export * from "./SearchComponent.vue?vue&type=script&lang=js&"
import style0 from "./SearchComponent.vue?vue&type=style&index=0&id=3ca34b95&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca34b95",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonComponent: require('/usr/src/nuxt-app/components/common/ButtonComponent.vue').default,InputComponent: require('/usr/src/nuxt-app/components/common/InputComponent.vue').default})
