const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload.orders
  },
  SET_ORDER(state, payload) {
    state.order = payload
  },
  SET_CART_LIST(state, payload) {
    state.cartList = payload
  },
};

export default mutations;
