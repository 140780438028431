
  import {mapGetters} from "vuex";
  import {userMenu} from "../../helpers/userMenu";
  import ButtonComponent from "./ButtonComponent";

  export default {
    name: "UserMenuComponent",
    components: {ButtonComponent},
    data() {
      return {
        isShowMenu: false,
        userMenu,
      }
    },
    async fetch() {
      await this.$store.dispatch(('user/getUser'))
    },
    computed: {
      ...mapGetters('user', ['isAuth', 'isSeller', 'user', 'favoritesCount', 'cartCount', 'isModerator']),
      avatar() {
        return this.user?.avatar ?
          {
            backgroundImage: `url(${this.user?.avatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          } : {
            backgroundImage: `url(${require('~/assets/images/icons/default-avatar.svg')})`,
            backgroundPosition: '5px 8px'
          }
      },
      visibleUserMenu() {
        let arr = this.userMenu.filter(item => item.title !== 'Я модератор')
        if (this.isModerator) {
          const moderatorItem = this.userMenu.find(item => item.title === 'Я модератор')
          const moderatorSubItems = moderatorItem ? this.userMenu.find(item => item.title === 'Я модератор').subItems : []
          arr = [...arr, ...moderatorSubItems]
        }
        return this.isSeller ? arr : arr.filter(item => item.title !== 'Мои продажи')
      },
    },
    methods: {
      toggleShowMenu(isShow) {
        this.isShowMenu = !isShow
      },
      closeMenu() {
        this.isShowMenu = false
      }
    }
  }
