
  import { mapGetters } from "vuex"
  import { userMenu } from "../../helpers/userMenu"
  import AccordionItem from './AccordionItem.vue'

  export default {
    name: "AsideMenuComponent",
    components: {
      AccordionItem,
    },
    data() {
      return {
        userMenu,
      };
    },

    computed: {
      ...mapGetters("user", ["isSeller", "isIndividual", 'isModerator']),
      visibleUserMenu() {
        const role = this.isIndividual ? "individual" : "legal"
        const withRoleMenu = this.userMenu.map(item => {
          if (item.subItems) {
            item.subItems = item.subItems.filter((subItem) => {
              if (subItem.role) {
                return subItem.role.includes(role)
              }
              return true
            });
          }
          return item
        });
        const withModeratorStatus = this.isModerator ? withRoleMenu : withRoleMenu.filter(item => item.title !== 'Я модератор')
        return this.isSeller ? withModeratorStatus : withModeratorStatus.filter(item => item.title !== "Мои продажи")
      }
    },

    methods: {
      isActive(url) {
        return this.$route.path === url
      }
    }
  };
