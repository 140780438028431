const state = () => ({
  filterDict: {
    musicVarieties: {
      title: 'Вид',
      query: 'music_variety_id'
    },
    genres: {
      title: 'Жанр',
      query: 'genres_ids',
    },
    moods: {
      title: 'Настроение',
      query: 'moods_ids',
    },
    themes: {
      title: 'Тематика',
      query: 'themes_ids',
    },
    instruments: {
      title: 'Инструменты',
      query: 'main_instruments_ids',
    },
    tempos: {
      title: 'Темп',
      query: 'tempo_id',
    },
    duration: {
      title: 'Длина',
      type: 'slider',
      query: 'music_duration_float',
    },
    tonalities: {
      title: 'Тональность',
      query: 'tonality_id',
    },
    mainKeys: {
      title: 'Основной ключ',
      query: 'main_key_id',
    },
  },
  filters: {},
  musicVarieties: [],
  genres: [],
  moods: [],
  themes: [],
  instruments: [],
  tempos: [],
  tonalities: [],
  mainKeys: [],
  duration: [
    {
      label: 'gte',
      value: 0
    },
    {
      label: 'lte',
      value: 3600
    }],
  countTrack: []
})

export default state


