
import { number, string } from 'vue-types'
import moment from 'moment'

export default {
  name: 'DurationComponent',
  props: {
    duration: number().def(0),
    tempo: string().def(''),
  },
  computed: {
    time() {
      return moment.utc(this.duration * 1000).format('mm:ss')
    },
    tempoShort() {
      let str = ''
      this.tempo
        .split(' ')
        .map((item, idx) => (str += idx <= 1 ? `${item.slice(0, 4)}. ` : ''))
      return str
    },
  },
}
