
  import InputComponent from "./InputComponent";
  import ButtonComponent from "./ButtonComponent";

  export default {
    name: 'SearchComponent',
    components: {InputComponent, ButtonComponent},
    data() {
      return {
        searchText: ''
      }
    },
    computed: {
      queryParams() {
        return this.$route.query
      }
    },
    watch: {
      queryParams(c, p) {
        if (!c.q && p.q) {
          this.searchText = ''
        }
      }
    },
    mounted() {
      if (this.queryParams.search) this.searchText = decodeURIComponent(this.queryParams.search)
    },
    methods: {
      changedSearchText(v) {
        this.searchText = v
      },
      getTrackList() {
        this.$store.dispatch('music/resetFilter')
        this.$router.push({name: 'catalog', query: {search: this.searchText}})
        this.$store.dispatch('tracks/getTrackList', {search: this.searchText})
      },
      clearSearchText() {
        this.searchText = ''
        if (this.$route.name === 'catalog') {
          this.$store.dispatch('tracks/getTrackList')
          this.$router.push({query: {}})
        }
      }
    },
  };
