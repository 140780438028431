const actions = {
  setCurrentTrack({commit}, track) {
    commit('SET_CURRENT_TRACK', track)
  },
  setNextTrack({commit}, track) {
    commit('SET_NEXT_TRACK', track)
  },
  setPrevTrack({commit}, track) {
    commit('SET_PREV_TRACK', track)
  },
  setIsPlay({commit}, isPlay) {
     commit('SET_IS_PLAY', isPlay)
  },
  setVolume({commit}, volume) {
    commit('SET_VOLUME', volume)
  },
  setPrefix({commit}, prefix) {
    commit('SET_PREFIX', prefix)
  },
  setFavoritesIcon({commit}, isFavorite) {
    commit('SET_FAVORITES_ICON', isFavorite)
  },
  setCartIcon({commit}, inCart) {
    commit('SET_CART_ICON', inCart)
  },
}


export default actions
