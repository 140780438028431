
  import { bool, number, string } from "vue-types";

  export default {
    name: "WaveSurferComponent",
    props: {
      trackId: number().def(0),
      isPlayer: bool().def(false),
      isMainPage: bool().def(false),
      isDetailPage: bool().def(false),
      waveName: string().def('-0')
    },
  }
