
  import { string } from "vue-types";
  import ShareNetworks from "../common/ShareNetworks";

  export default {
    name: "SharedTrackComponent",
    components: { ShareNetworks },
    props: {
      slug: string().def(""),
      trackLabel: string().def("")
    },
    data() {
      return {
        toggleShared: false
      };
    },

    computed: {
      host() {
        return process.env.baseURL || "http://localhost:3000";
      },
      title() {
        return `Композиция "${this.trackLabel}"`;
      }
    },
    methods: {
      sharedTrack() {
        this.toggleShared = !this.toggleShared;
      },
      closeShare() {
        this.toggleShared = false
      }
    }
  };
