import { render, staticRenderFns } from "./FavoriteComponent.vue?vue&type=template&id=20916e72&scoped=true&"
import script from "./FavoriteComponent.vue?vue&type=script&lang=js&"
export * from "./FavoriteComponent.vue?vue&type=script&lang=js&"
import style0 from "./FavoriteComponent.vue?vue&type=style&index=0&id=20916e72&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20916e72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LikeIconActive: require('/usr/src/nuxt-app/components/svg/likeIconActive.vue').default,LikeIcon: require('/usr/src/nuxt-app/components/svg/likeIcon.vue').default})
