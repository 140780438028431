
  import {string} from "vue-types"

  export default {
    name: "DownloadTrackComponent",

    props: {
      url: string().def(''),
      fileName: string().def(''),
    },
    methods: {
      downloadBlob(blob, filename) {
        const a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
      },

      downloadResource(url) {
        fetch(url,)
          .then(response => response.blob())
          .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            this.downloadBlob(blobUrl, this.fileName);
          })
          // eslint-disable-next-line no-console
          .catch(e => console.error(e));
      }
    }
  }
