
  import {mapGetters} from "vuex";
  import TopMenu from "../components/common/TopMenu";
  import HeaderMenu from "../components/common/HeaderMenu";
  import Footer from "../components/common/FooterComponent";
  import BreadcrumbsComponent from "../components/common/BreadcrumbsComponent";
  import PlayerComponent from "../components/common/PlayerComponent";
  import AsideMenuComponent from "../components/accountPage/AsideMenuComponent";

  export default {
    name: 'DefaultLayout',
    components: {AsideMenuComponent, PlayerComponent, BreadcrumbsComponent, Footer, HeaderMenu, TopMenu},
    head() {
      return this.$route.path === '/'
        ? {
          meta: [
            {
              hid: "description",
              name: "description",
              content: `На сайте MusiCat.store можно скачать лицензионную музыку для ваших проектов.
                  Для вас представлен каталог авторской  лицензионной инструментальной музыки разных жанров и тематик.
                  Вы можете выбирать по жанру, по темпу, по инструментам, по настроению, по тональности.`
            },
            {
              hid: "keywords",
              name: "keywords",
              content: `лицензионная музыка, скачать, интернет магазин, магазин, музыка для бизнеса, музыка для видео,
              музыка для ютьюб, музыка для рекламы, лицензия на музыку, скачать музыку, скачать лицензионную музыку,
              инструментальная музыка`
            },
            {
              name: "og:title",
              content: `Лицензионная музыка для видео, кино, радио, театра, рекламы, компьютерных игр, заведений`
            },
            {
              name: "og:description",
              content: `На сайте MusiCat.store можно скачать лицензионную музыку для ваших проектов.
                  Для вас представлен каталог авторской  лицензионной инструментальной музыки разных жанров и тематик.
                  Вы можете выбирать по жанру, по темпу, по инструментам, по настроению, по тональности.`
            }
          ],
          link: [
            {
              hid: "canonical",
              rel: "canonical",
              href: "https://musicat.store" + this.$route.path
            }
          ]
        }
        : {
          link: [
            {
              hid: "canonical",
              rel: "canonical",
              href: "https://musicat.store" + this.$route.path
            }
          ]
        }
    },
    computed: {
      ...mapGetters('player', ['currentTrack']),
      isMainPage() {
        return this.$route.path === '/'
      },
      isAccountPage() {
        return this.$route.path.includes('account') && (!this.$route.path.includes('cart') || /invoice\/\d+/.test(this.$route.path))
      }
    },
  }

