
  import { string } from "vue-types";

  export default {
    name: "TitleComponent",
    props: {
      title: string().required,
      type: string().def('h1')
    }
  };
