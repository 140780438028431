
export default {
  name: 'AccordionItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-default-prop
    isActive: {
      type: Function,
    },
  },
  data: () => ({
    isShowSubMenu: true,
  }),
  methods: {
    toggleSubMenu() {
      this.isShowSubMenu = !this.isShowSubMenu
    },
  },
}
