
  import { BIconList, BIconX } from 'bootstrap-vue'

  export default {
    name: 'TopMenu',
    components: {
      BIconList,
      BIconX,
    },
  }
