const state = () => ({
  user: null,
  favoritesCount: 0,
  cartCount: 0,
  collectionCount: 0,
  arrangementsCount: 0,
  myMusic: [],
  myCollection: [],
})

export default state
