
  import {string} from "vue-types";

  export default {
    name: "AuthorComponent",
    props: {
      author: string().def('').required,
      slug: string().def('').required
    }
  }
