
  import {mapGetters} from "vuex";
  import TopMenu from "../components/common/TopMenu";
  import HeaderMenu from "../components/common/HeaderMenu";
  import Footer from "../components/common/FooterComponent";
  import BreadcrumbsComponent from "../components/common/BreadcrumbsComponent";
  import PlayerComponent from "../components/common/PlayerComponent";
  import AsideMenuComponent from "../components/accountPage/AsideMenuComponent";

  export default {
    name: 'DefaultLayout',
    components: {AsideMenuComponent, PlayerComponent, BreadcrumbsComponent, Footer, HeaderMenu, TopMenu},
    head() {
      return {
        link: [
          {
            hid: "canonical",
            rel: "canonical",
            href: "https://musicat.store" + this.$route.path
          }
        ]
      }
    },
    computed: {
      ...mapGetters('player', ['currentTrack']),
    },
  }

