/* eslint-disable */
/* prettier-disable */
import { parseParams } from "../../helpers/parseParams";
import { apiCart, apiCartBuy, apiCartInvoice, apiOrders } from "../../service/endpoints";

const actions = {
  getCart({ commit }, params) {
    return this.$api.get(`${apiCart}`, { params: { size: 100 } })
      .then(async res => {
        const tracks = await res.data.results.map(item => item.offer);
        await commit("user/SET_CART_COUNT", res.data.count, { root: true });
        await commit("tracks/SET_TRACK_LIST", { trackList: [...tracks] }, { root: true });
        commit('SET_CART_LIST', tracks)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  addToCart({ commit, dispatch, rootState }, {id, routeName}) {
    const listIds = Array.isArray(id) ? id : [id];
    return this.$api.post(`${apiCart}`, { offer: listIds })
      .then(res => {
        if (res.data.analitics.length) {
          commit("user/SET_CART_COUNT", res.data.analitics[0].counter, { root: true });
          commit("tracks/ADD_TO_CART_TRACK", { id, routeName }, { root: true });
          if (rootState.player.currentTrack?.id === id) {
            dispatch('player/setCartIcon', true, { root: true })
          }
          if (routeName === 'account-cart') {
            dispatch('getCart')
          }
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        if (err.invalid.length) {
          // eslint-disable-next-line no-console
          console.log("Товар уже в корзине");
        }
      });
  },
  deletedCart({ commit, dispatch }, params) {
    return this.$api.delete(`${apiCart}`, {
      params,
      paramsSerializer: params => parseParams(params)
    })
      .then(res => {
        dispatch("getCart");
        dispatch("user/getUser", {}, { root: true });
        // eslint-disable-next-line no-console
        console.log(res);
      });
  },


  buyCart({ commit, dispatch }, ids) {
    return this.$api.post(`${apiCartBuy}`, {
      // offerType: 1,
      offers: ids
    }).then(res => {
      window.location.replace(res.data.redirect_url);
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  },
  downloadInvoice({ commit, dispatch }, ids) {
    return this.$api.get(`${apiCartInvoice}`, {
      responseType: "blob",
      params: {
        // offerType: 1,
        offers: ids
      },
      paramsSerializer: params => parseParams(params)
    }).then(response => {
      const headerLine = response.headers["content-disposition"];
      const startFileNameIndex = headerLine.indexOf("''") + 2;
      const filename = headerLine.substring(startFileNameIndex);
      return { blob: response.data, filename: decodeURIComponent(filename) };
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  },

  getOrders({ commit, rootState }, params) {
    return this.$api.get(`${apiOrders}`, { params })
      .then(res => {
         commit("SET_ORDERS", { orders: [...res.data.results] })
        commit("common/SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next }, { root: true });
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  getOrdersNext({ commit, rootState }, params) {
    return this.$api.get(`${apiOrders}`, { params })
      .then(res => {
        commit("SET_ORDERS", { orders: [...rootState.cart.orders, ...res.data.results] });
        commit("common/SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next }, { root: true });
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  getOrder({ commit, dispatch }, { patch }) {
    return this.$api.get(`${apiOrders}${patch}/`)
      .then(res => {
        commit("SET_ORDER", res.data);
        return res.data;
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  deleteOrder({ commit, dispatch }, { id }) {
    return this.$api.delete(`${apiOrders}${id}/`)
      .then(res => {

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        return err;
      });
  }
};
export default actions;
