export default {
  data() {
    return {
      currentTimeData: 0,
    }
  },
  methods: {
    audioTimer() {
      const audio = document.querySelector('#audio')

      audio.ontimeupdate = () => {
        this.currentTimeData = audio.currentTime / audio.duration || 0
        if (this.currentTimeData === 1) {
          this.$store.dispatch('player/setIsPlay', false)
        }
        if (window[`waves-0`].backend) {
          window[`waves-0`].seekAndCenter(this.currentTimeData)
        }
      }

      window['waves-0'].on('seek', (e) => {
        if (this.currentTimeData !== e) {
          audio.currentTime = audio.duration * e
        }
        if (window[`waves${this.waveName}`] && !window[`waves${this.waveName}`].isDestroyed) window[`waves${this.waveName}`].seekAndCenter(e)
      })
    },
  }
}
