const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_FAVORITES_COUNT(state, payload) {
    state.favoritesCount = payload
  },
  SET_CART_COUNT(state, payload) {
    state.cartCount = payload
  },
  SET_MY_MUSIC(state, payload) {
    state.myMusic = payload
  },
  SET_MY_COLLECTION(state, payload) {
    state.myCollection = payload
      .map(c => ({...c.offer_info, purchase_file_url: c.purchase_file_url}))
  },
  SET_COLLECTION_COUNT(state, payload) {
    state.collectionCount = payload
  },
  SET_MY_MUSIC_COUNT(state, payload) {
    state.arrangementsCount = payload
  }

}

export default mutations
