const mutations = {
  SET_CURRENT_TRACK(state, payload) {
    state.currentTrack = payload
  },
  SET_NEXT_TRACK(state, payload) {
    state.nextTrack = payload
  },
  SET_PREV_TRACK(state, payload) {
    state.prevTrack = payload
  },
  SET_IS_PLAY(state, payload) {
    state.isPlay = payload
  },
  SET_VOLUME(state, payload) {
    const audioEl = document.getElementById('audio')
    // значение 34 взято из компонента PlayerComponents   (maxVolume: 34)
    audioEl.volume = payload / 34
    state.volume = payload
  },
  SET_PREFIX(state, payload) {
    state.prefix = payload
  },
  SET_FAVORITES_ICON(state, payload) {
    state.currentTrack.is_favorite = payload
  },
  SET_CART_ICON(state, payload) {
    state.currentTrack.in_cart = payload
  },
}

export default mutations
