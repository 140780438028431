const getters = {
  currentTrack: (state) => state.currentTrack,
  nextTrack: (state) => state.nextTrack,
  prevTrack: (state) => state.prevTrack,
  isPlay: (state) => state.isPlay,
  volume: (state) => state.volume / 34,
  prefix: (state) => state.prefix
}

export default getters
