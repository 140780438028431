export const apiMusicVarieties = '/shop/music-varieties/' // Вид
export const apiGenres = '/shop/genres/' // Жанр
export const apiMoods = '/shop/tags/moods/' // Настроение
export const apiThemes = '/shop/tags/themes/' // Тематика
export const apiInstruments = '/shop/instruments/' // Инструмент
export const apiTempos = '/shop/tempos/' // Темп
// export const apiMusicVarieties = '/shop/music-varieties/' // Длина
export const apiTonalities = '/shop/tonalities/' // Тональность
export const apiMainKeys = '/shop/main-keys/' // Основной ключ

export const apiTrackList = '/shop-arrangements-search/' // Список треков
export const apiTrackListForModerator = '/v2/shop-arrangements/mslugget/' // Трек для модератора
export const apiFavoritesTrackList = '/v2/shop-arrangements/favorites/' // Список Избранного
export const apiCountTracksFilter = '/shop-arrangements-search/facets/' // Кол-во треков в фильтрах

export const apiUser = '/users/me/' // Информация о пользователе
export const apiUserFavorites = '/users/favorites/' // Избранное (добавление, удаление)
export const apiUserMyMusic = '/v2/shop-arrangements/' // Моя музыка
export const apiUserMyCollection = '/v2/collection/' // Мои заказы

export const apiCart = '/v2/cart/' // Корзина
export const apiCartBuy = '/cart/buy/' // Корзина
export const apiCartInvoice = '/cart/check/' // Корзина
export const apiOrders = '/orders/'

export const apiAuthors = '/authors/' // Авторы
export const apiAuthor = '/user/' // Автор
