import { render, staticRenderFns } from "./DownloadDocumentComponent.vue?vue&type=template&id=7ea8db37&scoped=true&"
import script from "./DownloadDocumentComponent.vue?vue&type=script&lang=js&"
export * from "./DownloadDocumentComponent.vue?vue&type=script&lang=js&"
import style0 from "./DownloadDocumentComponent.vue?vue&type=style&index=0&id=7ea8db37&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ea8db37",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownloadDocumentIcon: require('/usr/src/nuxt-app/components/svg/downloadDocumentIcon.vue').default})
