
  import {string} from "vue-types"

  export default {
    name: "DownloadMediaComponent",
    props: {
      url: string().def('')
    },
    methods: {}
  }
