const mutations = {
  SET_AUTHOR(state, payload) {
    state.author = payload
  },
  SET_AUTHORS(state, payload) {
    state.authors = payload
  },
  SET_AUTHORS_COUNT(state, payload) {
    state.authorsCount = payload
  },
  SET_IS_LOADING(state, payload) {
    state.loading = payload
  },
}

export default mutations
