import {
  apiMusicVarieties,
  apiGenres,
  apiMoods,
  apiThemes,
  apiInstruments,
  apiTempos,
  apiTonalities,
  apiMainKeys,
  apiCountTracksFilter,
} from "../../service/endpoints";

const actions = {
  getMusicVarieties({commit, rootState}) {
    return this.$api.get(`${apiMusicVarieties}`)
      .then(res => {
        commit('SET_MUSIC_VARIETIES', {
          musicVarieties: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getGenres({commit, rootState}) {
    return this.$api.get(`${apiGenres}`)
      .then(res => {
        commit('SET_GENRES', {
          genres: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getMoods({commit, rootState}) {
    return this.$api.get(`${apiMoods}`)
      .then(res => {
        commit('SET_MOODS', {
          moods: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getThemes({commit, rootState}) {
    return this.$api.get(`${apiThemes}`)
      .then(res => {
        commit('SET_THEMES', {
          themes: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getInstruments({commit, rootState}) {
    return this.$api.get(`${apiInstruments}`)
      .then(res => {
        commit('SET_INSTRUMENTS', {
          instruments: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getTempos({commit, rootState}) {
    return this.$api.get(`${apiTempos}`)
      .then(res => {
        commit('SET_TEMPOS', {
          tempos: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getTonalities({commit, rootState}) {
    return this.$api.get(`${apiTonalities}`)
      .then(res => {
        commit('SET_TONALITIES', {
          tonalities: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getMainKeys({commit, rootState}) {
    return this.$api.get(`${apiMainKeys}`)
      .then(res => {
        commit('SET_MAIN_KEYS', {
          mainKeys: res.data,
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getCountTracksFilters({commit} , params='', url=  apiCountTracksFilter) {
    // return this.$api.get(`${url}?${params}`)
    //   .then(res => {
    //     commit('SET_COUNT_TRACKS', {
    //       countTrack: res.data.fields
    //     })
    //   }).catch(err => {
    //     // eslint-disable-next-line no-console
    //     console.log(err)
    //   })
  },
  changedFilter({commit, state}, params) {
    commit('CHANGED_FILTER', {...params})
  },

  resetFilter({commit, dispatch}) {
    const {q} = this.$router.history.current.query
    if (q) {
      this.$router.push({query: {q}})
    } else {
      this.$router.push({query: {}})
    }
    commit('RESET_FILTER')
  }
}

export default actions
