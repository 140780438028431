import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _235b5e24 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _86238302 = () => interopDefault(import('../pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _971485e0 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5459fb96 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _349102be = () => interopDefault(import('../pages/account/cart.vue' /* webpackChunkName: "pages/account/cart" */))
const _9a082a88 = () => interopDefault(import('../pages/account/collection.vue' /* webpackChunkName: "pages/account/collection" */))
const _21315cb0 = () => interopDefault(import('../pages/account/contract.vue' /* webpackChunkName: "pages/account/contract" */))
const _1a2e2fae = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _1312907c = () => interopDefault(import('../pages/account/invoice/index.vue' /* webpackChunkName: "pages/account/invoice/index" */))
const _23ad4162 = () => interopDefault(import('../pages/account/my-music.vue' /* webpackChunkName: "pages/account/my-music" */))
const _167fd30c = () => interopDefault(import('../pages/account/invoice/_slug.vue' /* webpackChunkName: "pages/account/invoice/_slug" */))
const _1395a441 = () => interopDefault(import('../pages/catalog/preview/_slug.vue' /* webpackChunkName: "pages/catalog/preview/_slug" */))
const _8990c592 = () => interopDefault(import('../pages/authors/_slug.vue' /* webpackChunkName: "pages/authors/_slug" */))
const _9a81c870 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _235b5e24,
    name: "account"
  }, {
    path: "/authors",
    component: _86238302,
    name: "authors"
  }, {
    path: "/catalog",
    component: _971485e0,
    name: "catalog"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/registration",
    component: _5459fb96,
    name: "registration"
  }, {
    path: "/account/cart",
    component: _349102be,
    name: "account-cart"
  }, {
    path: "/account/collection",
    component: _9a082a88,
    name: "account-collection"
  }, {
    path: "/account/contract",
    component: _21315cb0,
    name: "account-contract"
  }, {
    path: "/account/favorites",
    component: _1a2e2fae,
    name: "account-favorites"
  }, {
    path: "/account/invoice",
    component: _1312907c,
    name: "account-invoice"
  }, {
    path: "/account/my-music",
    component: _23ad4162,
    name: "account-my-music"
  }, {
    path: "/account/invoice/:slug",
    component: _167fd30c,
    name: "account-invoice-slug"
  }, {
    path: "/catalog/preview/:slug?",
    component: _1395a441,
    name: "catalog-preview-slug"
  }, {
    path: "/authors/:slug",
    component: _8990c592,
    name: "authors-slug"
  }, {
    path: "/catalog/:slug",
    component: _9a81c870,
    name: "catalog-slug"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/ru/*",
    component: _b4d4bac6,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
