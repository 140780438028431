
  import { string } from "vue-types";
  import VkIcon from "~/assets/images/icons/vk.svg";
  import TelegramIcon from "~/assets/images/icons/telegram.svg";
  import WhatsAppIcon from "~/assets/images/icons/whatsapp.svg";
  import EmailIcon from "~/assets/images/icons/email.svg";

  export default {
    name: "ShareNetworks",
    props: {
      url: string().required,
      title: string().required
    },
    data() {
      return {
        networks: [
          { label: "Vk", icon: VkIcon },
          { label: "Telegram", icon: TelegramIcon },
          { label: "WhatsApp", icon: WhatsAppIcon },
          { label: "Email", icon: EmailIcon }
        ]
      };
    },
  };

