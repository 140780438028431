export const conversionIdToValue = (arr) => {
  return arr.map(item => {
      const obj =
        {
          ...item,
          label: item.name || item.label,
          value: item.id || item.value,
        }

      delete obj.id
      delete obj.name
      return obj
    }
  )

}
