const state = () => ({
  currentTrack: null,
  nextTrack: null,
  PrevTrack: null,
  isPlay: false,
  volume: 7,
  mute: false,
  prefix: ''
})

export default state
