
import { mapGetters } from 'vuex'
import { breadcrumbs } from '@/helpers/breadcrumbs'

export default {
  name: 'BreadcrumbsComponent',
  data() {
    return {
      excludeLinks: [],
    }
  },
  computed: {
    ...mapGetters('tracks', ['track']),
    ...mapGetters('author', ['author']),

    customSlug() {
      if (this.track && this.track.id) {
        return this.track.name
      }
      if (this.author) {
        return this.author.brand_name || this.author.full_name
      }
      return ''
    },
    crumbs() {
      const { fullPath } = this.$route

      const fullPath2 = fullPath.endsWith('/')
        ? fullPath.replace(/.$/, '')
        : fullPath

      const params = fullPath2.startsWith('/')
        ? fullPath2.substring(1).split('/')
        : fullPath2.split('/')

      const crumbs = []
      let path = ''
      params.forEach((param) => {
        path = `${path}/${param}`

        const match = this.$router.match(path)
        if (match.name !== null) {
          crumbs.push({
            title:
              breadcrumbs[param.split('?')[0].replace(/-/g, '_')] ||
              this.customSlug ||
              match.params.slug,
            hasLink: !this.excludeLinks.includes(
              param.split('?')[0].replace(/-/g, '_')
            ),
            ...match,
          })
        }
      })
      return crumbs
    },
  },
}
