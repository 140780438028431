import { render, staticRenderFns } from "./UserMenuComponent.vue?vue&type=template&id=fd5cc5dc&scoped=true&"
import script from "./UserMenuComponent.vue?vue&type=script&lang=js&"
export * from "./UserMenuComponent.vue?vue&type=script&lang=js&"
import style0 from "./UserMenuComponent.vue?vue&type=style&index=0&id=fd5cc5dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd5cc5dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonComponent: require('/usr/src/nuxt-app/components/common/ButtonComponent.vue').default})
