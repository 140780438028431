import {
  apiTrackList,
  apiFavoritesTrackList,
  apiUserMyMusic,
  apiUserMyCollection,
  apiTrackListForModerator
} from "../../service/endpoints";
import { parseParamsCatalog } from "../../helpers/parseParams";

const actions = {
  getTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiTrackList}`,
      {
        params,
        paramsSerializer: params => parseParamsCatalog(params)
      }
    )
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: res.data.results });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });
        commit("music/SET_COUNT_TRACKS", {
          countTrack: res.data.facets
        }, { root: true });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  loadingTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiTrackList}`, { params })
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: [...rootState.tracks.trackList, ...res.data.results] });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });
        commit("music/SET_COUNT_TRACKS", {
          countTrack: res.data.facets
        }, { root: true });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  getFavoritesTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiFavoritesTrackList}`, { params })
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: res.data.results });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });
        commit('user/SET_FAVORITES_COUNT', res.data.count, { root: true })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        return err;
      });
  },

  loadingFavoriteTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiFavoritesTrackList}`, { params })
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: [...rootState.tracks.trackList, ...res.data.results] });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  getTrack({ commit, dispatch }, { patch, isModerator }) {
    if (isModerator) {
      return this.$api.get(`${apiTrackListForModerator}`, { params: { slug: patch } })
        .then(res => {
          commit("SET_TRACK", { track: res.data });
          // dispatch('getTracksMoreLikeThis', {url: `${res.data.more_like_this}?size=4`,})

        }).catch(err => {
          return { error: err.message }
        });
    }

    return this.$api.get(`${apiTrackList}${patch}/`)
      .then(res => {
        commit("SET_TRACK", { track: res.data });
        dispatch("getTracksMoreLikeThis", { url: `${res.data.more_like_this}?size=4` });
        return res.data

      }).catch(err => {
        return { error: err.message }
      });
  },
  getTracksMoreLikeThis({ commit, rootState }, { url }) {
    return this.$api.get(`${url}`)
      .then(res => {
        commit("SET_TRACK_MORE_LIKE_THIS", { tracksMoreLikeThis: res.data.results });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  loadingTracksMoreLikeThis({ commit, rootState }, { url }) {
    return this.$api.get(`${url}`)
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: [...rootState.tracks.trackList, ...res.data.results] });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },


  getMyMusicTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiUserMyMusic}`, { params })
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: res.data.results });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  loadingMyMusicTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiUserMyMusic}`, { params })
      .then(res => {
        commit("SET_TRACK_LIST", { trackList: [...rootState.tracks.trackList, ...res.data.results] });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },
  getMyCollectionsTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiUserMyCollection}`, { params })
      .then(res => {
        const myCollection = res.data.results.map(c => ({ ...c.offer_info, purchase_file_url: c.purchase_file_url }));
        commit("SET_TRACK_LIST", { trackList: myCollection });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  },

  loadingMyCollectionsTrackList({ commit, rootState }, params) {
    return this.$api.get(`${apiUserMyCollection}`, { params })
      .then(res => {
        const myCollection = res.data.results.map(c => ({ ...c.offer_info, purchase_file_url: c.purchase_file_url }));
        commit("SET_TRACK_LIST", { trackList: [...rootState.tracks.trackList, ...myCollection] });
        commit("SET_NEXT_PAGE_URL", { nextPageUrl: res.data.next });

      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }
};


export default actions;
