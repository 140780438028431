import {conversionIdToValue} from "../../helpers/conversionIdToValue";

const getters = {
  musicVarieties: (state) => state.musicVarieties,
  genres: (state) => state.genres,
  moods: (state) => state.moods,
  themes: (state) => state.themes,
  instruments: (state) => state.instruments,
  tempos: (state) => state.tempos,
  tonalities: (state) => state.tonalities,
  mainKeys: (state) => state.mainKeys,
  filterDict: (state) => state.filterDict,

  filtersInit: (state) => {

    const filters = []
    for (const key of Object.keys(state.filterDict)) {
      let valueWithCount = conversionIdToValue(state[key])

      if (state.filterDict[key].query && state.countTrack) {

        const {query} = state.filterDict[key]
        valueWithCount = valueWithCount.map(v => {
          if (state.countTrack[query]) {
            const count = state.countTrack[query].find(c => +c.text === +v.value)
            return {
              ...v,
              count: count?.count || 0
            }
          }
          return {
            ...v,
            count: 0
          }
        })
      }
      filters.push({
        ...state.filterDict[key],
        name: key,
        value: [...valueWithCount]
      })
    }

    return filters
  },

  filters: (state) => state.filters,
  countTrack: (state) => state.countTrack || []

}

export default getters
