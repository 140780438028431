
  import {bool, number} from "vue-types"
  import {mapGetters} from "vuex"
  import AddToCartIcon from "../svg/addToCartIcon.vue"
  import InCartIcon from "../svg/inCartIcon.vue"

  export default {
    name: "CartComponent",
    components: {
      AddToCartIcon,
      InCartIcon,
    },

    props: {
      id: number().required,
      isPurchased: bool().def(false),
      inCart: bool().def(false),
      disabled: bool().def(false),
    },
    computed: {
      ...mapGetters('user', ['isAuth']),
      notActive() {
        return !this.isAuth || this.disabled
      },
      title() {
        if (this.isPurchased) {
          return 'Композиция была куплена'
        }
        if (this.inCart) {
          return 'Композиция в корзине'
        }
        return 'Добавить в корзину'
      }
    },

    methods: {
      addToCart() {
        if (this.isAuth && !this.isPurchased && !this.inCart) {
          this.$store.dispatch('cart/addToCart', {id: this.id, routeName: this.$route.name})
        }
      },
    },
  }
