const mutations = {
  SET_NEXT_PAGE_URL(state, payload) {
    state.nextPageUrl = payload.nextPageUrl
    state.nextPage = () => {
      const url = new URL(payload.nextPageUrl)
      const params = new URLSearchParams(url.search)
      return params.get('page')
    }
  },
  SET_PAGE_COUNT(state, payload) {
    state.pageCount = payload.pageCount
  },
}

export default mutations
