export const parseParams = (params, separator = ",") => {

  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

    if (!isParamTypeObject) {
       options += `${key}=${params[key].toString().split(",").join(separator)}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        options += `${key}=${element}&`;
      });
    }
  });
  return options ? options.slice(0, -1) : options;
};

export const parseParamsCatalog = (params) => {

  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    params[key] && params[key].toString().split(",").forEach(k => {
        if(key.includes('music_duration_float') || key === 'size' || key === 'search') {
          options +=`${key}=${encodeURIComponent(k)}&`
        } else {
          options +=`${key}__term=${k}&`
        }
      });
   })
  return options.slice(0, -1)
};

