import {apiUser, apiUserFavorites, apiUserMyMusic, apiUserMyCollection, apiFavoritesTrackList} from "../../service/endpoints";

const actions = {
  getUser({commit, dispatch}, param) {
    return this.$api.get(`${apiUser}`)
      .then(res => {
        commit('SET_USER', res.data)
        // commit('SET_FAVORITES_COUNT', res.data.count)
        commit('SET_COLLECTION_COUNT', res.data.collection_arrangements_count)
        commit('SET_MY_MUSIC_COUNT', res.data.total_checked_arrangements_count)
        commit('SET_CART_COUNT', res.data.cart_count)
        if (res.data.isAuth) {
          return this.$api.get(`${apiFavoritesTrackList}`)
            .then(res => {
              commit('SET_FAVORITES_COUNT', res.data.count)
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log(err)
            })
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },

  addedFavorite({commit, dispatch, rootState}, {id, routeName}) {
    return this.$api.post(
      `${apiUserFavorites}`,
      {offer: id},
    ).then(res => {
      commit('SET_FAVORITES_COUNT', res.data.counter)
      commit('tracks/TOGGLE_FAVORITE_TRACK', {id, routeName, value: true}, {root: true})
      if (rootState.player.currentTrack?.id === id) {
        dispatch('player/setFavoritesIcon', true, { root: true })
      }
      if (routeName === 'account-favorites') {
        dispatch('tracks/getFavoritesTrackList', null, { root: true })
      }
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  },
  removedFavorite({commit, dispatch, rootState}, {id, routeName}) {
    return this.$api.delete(
      `${apiUserFavorites}`,
      { params: {items: id} }
    ).then(res => {
      commit('SET_FAVORITES_COUNT', res.data.counter)
      commit('tracks/TOGGLE_FAVORITE_TRACK', {id, routeName, value: false}, {root: true})
      if (rootState.player.currentTrack?.id === id) {
        dispatch('player/setFavoritesIcon', false, { root: true })
      }
      if (routeName === 'account-favorites') {
        dispatch('tracks/getFavoritesTrackList', null, { root: true })
      }
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  },
  getMyMusic({commit}, params) {
    return this.$api.get(`${apiUserMyMusic}`, {params})
      .then(res => {
        commit('SET_MY_MUSIC', res.data.results)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  getMyCollection({commit}, params) {
    return this.$api.get(`${apiUserMyCollection}`, {params})
      .then(res => {
        commit('SET_MY_COLLECTION', res.data.results)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
}

export default actions
