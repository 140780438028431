import { apiAuthor, apiAuthors } from '@/service/endpoints'

const actions = {
  getAuthors({ commit }, params) {
    const size = process.env.pageSize
    commit('SET_IS_LOADING', true)
    return this.$api
      .get(`${apiAuthors}`, {
        params: { size, ...params },
      })
      .then((res) => {
        commit('SET_AUTHORS', res.data.results)
        commit('SET_AUTHORS_COUNT', res.data.count)
        commit(
          'common/SET_PAGE_COUNT',
          { pageCount: Math.ceil(res.data.count / size) },
          { root: true }
        )
        return res.data.results
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('err', err)
        return Promise.reject(err)
      })
      .finally(() => commit('SET_IS_LOADING', false))
  },
  getAuthor({ commit }, params) {
    return this.$api
      .get(`${apiAuthor}${params.slug}/`)
      .then((res) => {
        commit('SET_AUTHOR', res.data)
        return res
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('err', err)
        return Promise.reject(err)
      })
  },
  getAuthorTracks({ commit }, params) {
    return this.$api
      .get(`${apiAuthor}${params.slug}/tracks/`)
      .then((res) => {
        commit(
          'tracks/SET_TRACK_LIST',
          { trackList: res.data.results },
          { root: true }
        )
        commit(
          'tracks/SET_NEXT_PAGE_URL',
          { nextPageUrl: res.data.next },
          { root: true }
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
  loadingAuthorTracks({ commit, rootState }, params) {
    return this.$api
      .get(`${apiAuthor}${params.slug}/tracks/`, {
        params: { page: params.page },
      })
      .then((res) => {
        commit(
          'tracks/SET_TRACK_LIST',
          { trackList: [...rootState.tracks.trackList, ...res.data.results] },
          { root: true }
        )
        commit(
          'tracks/SET_NEXT_PAGE_URL',
          { nextPageUrl: res.data.next },
          { root: true }
        )
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  },
}

export default actions;
