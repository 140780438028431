/* eslint-disable */
/* prettier-disable */
const mutations = {
  SET_TRACK_LIST(state, payload) {
    state.trackList = payload.trackList
  },
  SET_NEXT_PAGE_URL(state, payload) {
    state.nextPageUrl = payload.nextPageUrl
    state.nextPage = () => {
      const url = new URL(payload.nextPageUrl);
      const params = new URLSearchParams(url.search);
      return params.get('page')
    }
  },
  // ADD_FAVORITE_TRACK(state, id) {
  //   const track = state.trackList.find(t => t.id === id)
  //   const index = state.trackList.indexOf(track)
  //   if (index >= 0) {
  //     state.trackList[index].is_favorite = true
  //   }

  //   if (state.track.id === id) {
  //     state.track.is_favorite = true
  //   }
  // },
  // REMOVE_FAVORITE_TRACK(state, id) {
  //   const track = state.trackList.find(t => t.id === id)
  //   const index = state.trackList.indexOf(track)
  //   if (index >= 0) {
  //     state.trackList[index].is_favorite = false
  //   }

  //   if (state.track.id === id) {
  //     state.track.is_favorite = false
  //   }
  // },
  TOGGLE_FAVORITE_TRACK(state, { id, routeName, value }) {
    if (routeName === 'authors' && this.state.author.authors.length) {
      this.state.author.authors.forEach((item) => {
        if (item?.tracks.length) {
          item.tracks.forEach(i => {
            if (i.id === id) {
              i.is_favorite = value
            }
          })
        }
      })
    } else {
      const track = state.trackList.find(t => t.id === id)
      const index = state.trackList.indexOf(track)
      if (index >= 0) {
        state.trackList[index].is_favorite = value
      }

      if (state.track.id === id) {
        state.track.is_favorite = value
      }
    }
  },
  SET_TRACK(state, payload) {
    state.track = payload.track
  },
  SET_TRACK_MORE_LIKE_THIS(state, payload) {
    state.trackList = payload.tracksMoreLikeThis
  },
  ADD_TO_CART_TRACK(state, { id, routeName }) {
    if (routeName === 'authors' && this.state.author.authors.length) {
      this.state.author.authors.forEach((item) => {
        if (item?.tracks.length) {
          item.tracks.forEach(i => {
            if (i.id === id) {
              i.in_cart = true
            }
          })
        }
      })
    } else {
      const track = state.trackList.find(t => t.id === id)
      const index = state.trackList.indexOf(track)
      if (index !== -1) {
        state.trackList[index].in_cart = true
      }
      if (state.track.id === id) {
        state.track.in_cart = true
      }
    }
  },
}

export default mutations
