
  import {bool, number} from "vue-types"
  import {mapGetters} from "vuex"
  import LikeIcon from "../svg/likeIcon.vue"
  import LikeIconActive from "../svg/likeIconActive.vue"

  export default {
    name: "FavoriteComponent",
    components: {
      LikeIcon,
      LikeIconActive
    },
    props: {
      isFavorite: bool().def(false),
      disabled: bool().def(false),
      id: number().required
    },
    computed: {
      ...mapGetters('user', ['isAuth']),
      notActive() {
        return !this.isAuth || this.disabled
      }
    },
    methods: {
      addedFavorite() {
        this.$store.dispatch('user/addedFavorite', {id: this.id, routeName: this.$route.name})
      },
      removedFavorite() {
        this.$store.dispatch('user/removedFavorite', {id: this.id, routeName: this.$route.name})
      },
    }
  }
