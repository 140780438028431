const mutations = {
  SET_MUSIC_VARIETIES(state, payload) {
    state.musicVarieties = payload.musicVarieties
  },
  SET_GENRES(state, payload) {
    state.genres = payload.genres
  },
  SET_MOODS(state, payload) {
    state.moods = payload.moods
  },
  SET_THEMES(state, payload) {
    state.themes = payload.themes
  },
  SET_INSTRUMENTS(state, payload) {
    state.instruments = payload.instruments
  },
  SET_TEMPOS(state, payload) {
    state.tempos = payload.tempos
  },
  SET_TONALITIES(state, payload) {
    state.tonalities = payload.tonalities
  },
  SET_MAIN_KEYS(state, payload) {
    state.mainKeys = payload.mainKeys
  },
  SET_COUNT_TRACKS(state, payload) {
    state.countTrack = Object.values(payload.countTrack).map(o => {
      delete o.doc_count; // убираем лишнее поле
      return o;
    })
  },
  CHANGED_FILTER(state, filter) {
    state.filters = {
      ...state.filters,
      ...filter,
    }
  },
  RESET_FILTER(state) {
    state.filters = {}
  }
}

export default mutations
