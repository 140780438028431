import { render, staticRenderFns } from "./main.vue?vue&type=template&id=4a875ce7&scoped=true&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a875ce7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopMenu: require('/usr/src/nuxt-app/components/common/TopMenu.vue').default,HeaderMenu: require('/usr/src/nuxt-app/components/common/HeaderMenu.vue').default,PlayerComponent: require('/usr/src/nuxt-app/components/common/PlayerComponent.vue').default})
