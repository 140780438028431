
  import {bool, string} from 'vue-types'

  export default {
    name: "TitleComponent",
    props: {
      title: string().def('').required,
      slug: string().def('').required,
      isApproved: bool().def(false),
      isNew: bool().def(false),
    }
  }
