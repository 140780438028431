
  import { mapGetters } from "vuex";

  export default {
    name: "FooterComponent",
    computed: {
      ...mapGetters('player', ['currentTrack']),

    }
  }
