
  import { mapGetters } from 'vuex'
  import Hls from 'hls.js'
  import ThumbnailComponent from '../track/ThumbnailComponent'
  import TitleComponent from '../track/TitleComponent'
  import AuthorComponent from '../track/AuthorComponent'
  import WaveSurferComponent from '../track/WaveSurferComponent'
  import DurationComponent from '../track/DurationComponent'
  import IconsListComponent from '../track/IconsListComponent'
  import DeleteIcon from '../../components/svg/deleteIcon'
  import audioTimer from '@/mixins/audioTimer'

  export default {
    name: 'PlayerComponent',
    components: {
      IconsListComponent,
      DurationComponent,
      WaveSurferComponent,
      AuthorComponent,
      TitleComponent,
      ThumbnailComponent,
      DeleteIcon,
    },
    mixins: [audioTimer],
    data() {
      return {
        options: {},
        maxVolume: 34,
        isDrawing: false,
        isErrorHls: false,
        audioPromise: undefined,
        isMuted: false,
      }
    },
    computed: {
      ...mapGetters('tracks', ['trackList']),
      ...mapGetters('player', ['isPlay', 'currentTrack', 'volume']),
      ...mapGetters('user', ['mySlug']),
      iconsList() {
        if (this.currentTrack.is_purchased) {
          return ['same', 'download', 'shared', 'cart']
        }
        return ['favorites', 'same', 'download', 'shared', 'cart']
      },
      indexTrack() {
        return this.trackList.indexOf(this.currentTrack)
      },
      hasPrev() {
        return this.$route.name === 'account' ? false : this.indexTrack > 0
      },
      hasNext() {
        return this.$route.name === 'account'
          ? false
          : this.indexTrack < this.trackList.length - 1
      },
      waveName() {
        return `-${this.currentTrack.id}`
      },
    },

    watch: {
      currentTrack(c, p) {
        window['waves-0'].unAll()
        window['waves-0'].destroy()
        this.$store.dispatch('player/setIsPlay', false)

        this.initWaveForm()
      },

      isPlay(c, p) {
        const audio = document.getElementById('audio')
        if (c && !this.isErrorHls) {
          this.audioPromise = audio.play()
        } else if (this.audioPromise !== undefined) {
          this.audioPromise
            .then(() => {
              audio.pause()
            })
            .catch(() => {
              audio.pause()
            })
        }
      },
    },
    mounted() {
      if (localStorage.getItem('volume') === null) {
        localStorage.setItem('volume', 7)
        this.$store.dispatch('player/setVolume', 7)
      } else {
        this.$store.dispatch('player/setVolume', localStorage.getItem('volume'))
      }

      const volumeEl = document.getElementById('volume')
      volumeEl.addEventListener('mousedown', this.mouseDown)
      volumeEl.addEventListener('mousemove', this.mouseMove)
      window.addEventListener('mouseup', this.mouseUp)

      this.initWaveForm()

      const audioEl = document.querySelector('#audio')
      audioEl.addEventListener('ended', (ev) => {
        this.nextTrackPlay()
      })
    },

    beforeDestroy() {
      this.closePlayer()
    },

    methods: {
      mouseUp() {
        if (this.isDrawing) {
          this.isDrawing = false
        }
      },
      mouseDown(e) {
        if (e.offsetX < 5) {
          this.setVolume(0)
        } else {
          this.setVolume(Math.ceil(e.offsetX / 2))
        }
        this.isDrawing = true
      },
      mouseMove(e) {
        if (this.isDrawing) {
          if (e.offsetX < 5) {
            this.setVolume(0)
          } else {
            this.setVolume(Math.ceil(e.offsetX / 2))
          }
        }
      },

      async initWaveForm() {
        let audioEl = document.querySelector('#audio')
        let fakeAudioEl = document.querySelector('#fakeAudio')

        if (window.hls) {
          window.hls.destroy()
        }
        // fakeAudio нужен для отображения волны.
        if (!fakeAudioEl) {
          const fakeAudio = document.createElement('audio')
          fakeAudio.id = 'fakeAudio'
          audioEl.after(fakeAudio)
        }
        // Создание waveSurfer
        // eslint-disable-next-line no-undef
        window['waves-0'] = await WaveSurfer.create({
          container: '.wavesform-0',
          waveColor: 'rgba(37, 37, 37, .2)',
          progressColor: 'rgba(37, 37, 37, 1)',
          normalize: true,
          barWidth: 2,
          barHeight: 1, // the height of the wave
          barGap: null,
          height: 30,
          partialRender: true,
          backend: 'MediaElement',
        })
        await window[`waves-0`].setVolume(this.volume)

        audioEl = document.querySelector('#audio')
        fakeAudioEl = document.querySelector('#fakeAudio')
        const audioSrc = this.currentTrack.playlist
        const formData = this.currentTrack.waveform_data
        // Stream audio
        if (audioEl.canPlayType('application/vnd.apple.mpegurl')) {
          audioEl.src = audioSrc
        } else if (Hls.isSupported()) {
          window.hls = new Hls()
          window.hls.loadSource(audioSrc)
          window.hls.attachMedia(audioEl)
          window.hls.on(Hls.Events.ERROR, (event, data) => {
            this.isErrorHls = true
            this.$store.dispatch('player/setIsPlay', false)
            window.hls.destroy()
            // eslint-disable-next-line no-console
            console.error('fatal error:', data.type, data)
          })
          window.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.isErrorHls = false
            window['waves-0'].backend.setPeaks(
              JSON.parse(formData).data,
              JSON.parse(formData).length
            )
            window['waves-0'].load(fakeAudioEl, JSON.parse(formData).data)
            this.$store.dispatch('player/setIsPlay', true)
          })
        }
      },

      setVolume(position) {
        window[`waves-0`].setVolume(position / this.maxVolume)
        this.$store.dispatch('player/setVolume', position)
        localStorage.setItem('volume', position)
      },

      playPause() {
        this.$store.dispatch('player/setIsPlay', !this.isPlay)
        // this.audioTimer()
      },

      async prevTrackPlay() {
        if (this.hasPrev) {
          await this.$store.dispatch(
            'player/setCurrentTrack',
            this.trackList[this.indexTrack - 1]
          )
          // await this.playPause()
          this.audioTimer()
          await this.$store.dispatch('player/setIsPlay', !this.isPlay)
        }
      },
      async nextTrackPlay() {
        if (this.hasNext) {
          await this.$store.dispatch(
            'player/setCurrentTrack',
            this.trackList[this.indexTrack + 1]
          )
          // await this.playPause()
          this.audioTimer()
          await this.$store.dispatch('player/setIsPlay', !this.isPlay)
        }
      },
      toggleMuteAudio(val) {
        const audioEl = document.querySelector('#audio')
        audioEl.muted = this.isMuted = val
      },
      async closePlayer() {
        await this.$store.dispatch('player/setIsPlay', false)
        await this.$store.dispatch('player/setCurrentTrack', null)
        window.hls.destroy()
        window['waves-0'].unAll()
        window['waves-0'].destroy()

        const volumeEl = document.getElementById('volume')
        if (volumeEl) {
          volumeEl.removeEventListener('mousedown', this.mouseDown)
          volumeEl.removeEventListener('mousemove', this.mouseMove)
          window.removeEventListener('mouseup', this.mouseUp)
        }
      },
    },
  }
