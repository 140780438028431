export const userMenu = [
  {
    title: "Мои заказы", url: "/account/collection", subItems: [
      { title: "Мои заказы", url: "/account/collection", role: ["legal"] },
      { title: "Мои счета", url: "/account/invoice", role: ["legal"] }
    ]
  },
  { title: "Моё избранное", url: "/account/favorites" },
  { title: "Моя музыка", url: "/account/my-music" },
  { title: "Мои продажи", url: "/ru/my/accounts/transfers/" },
  {
    title: "Настройки", url: "/account", subItems: [
      { title: "Редактировать профиль", url: "/ru/my/settings/profile/", external: true },
      // {title: 'Расширенные данные ФЛ', url: '/ru/my/settings/profile/',},
      // {title: 'Расширенные данные юрлица', url: '/ru/my/settings/profile/',},
      // {title: 'Расширенные данные автора', url: '/ru/my/settings/profile/',},
      { title: "Договор", url: "/account/contract" }
    ]
  },
  {
    title: "Я модератор", url: "#", subItems: [
      { title: "Модерировать новый контент", url: "/ru/my/moderator/musicat/", external: true },
      { title: "Модерировать в админке", url: "/ru/adminfake2019/shop/shopoffers/", external: true },
    ],
  },
];
